<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <div class="flex flex-col items-stretch justify-center gap-2">
        @if (organizationRoleControl.value >= 0) {
            <div>
                <div class="mat-subtitle-2">
                    {{ 'organization.role' | translate }}
                </div>
                <sz-select
                    [formControl]="organizationRoleControl"
                    [options]="organizationRoles"
                />
            </div>
        }
        <div>
            <div class="mat-subtitle-2">
                {{ 'common.search' | translate }}
            </div>
            <sz-input [formControl]="filterControl" />
        </div>
        <div class="h-72 overflow-auto">
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="
                    selectedOrganizationId = $event.options[0].value
                "
            >
                @for (
                    organization of filteredOrganizationList
                        | search: filterTerm : ['name'];
                    track organization.id
                ) {
                    <mat-list-option
                        [value]="organization.id"
                        [selected]="selectedOrganizationId === organization.id"
                        class="!h-16 !rounded-md"
                        [ngClass]="
                            selectedOrganizationId === organization.id
                                ? '!bg-primary-500/30'
                                : ''
                        "
                    >
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-row items-center gap-3">
                                <img
                                    class="h-14 w-14 object-contain"
                                    [src]="
                                        getImageUrl(organization)
                                            | secure
                                            | async
                                    "
                                    alt="organization.logo"
                                />
                                <span>{{ organization.name }}</span>
                            </div>
                            @if (
                                pinnedOrganizations.includes(organization.id)
                            ) {
                                <div class="flex items-center">
                                    <mat-icon
                                        class="!h-5 !w-5 text-[goldenrod]"
                                        svgIcon="pin"
                                    />
                                </div>
                            }
                        </div>
                    </mat-list-option>
                }
            </mat-selection-list>
        </div>
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="!selectedOrganizationId"
        (primaryClick)="dialogRef.close(selectedOrganization)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
