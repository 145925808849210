import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/dynamic-api';
import { getOrganizationImageUrl } from 'src/app/services/api/organizations-api';
import { CustomerRole, customerRoleArray } from 'src/app/services/constants';
import { getCustomerId, getCustomerRole } from 'src/app/shared/auth-utils';
import { Organization } from 'src/app/shared/models/customer';

const organizationRoleTerms = [
    'organization.role.super',
    'organization.role.administrator',
    'organization.role.oem',
    'organization.role.dealer',
    'organization.role.client',
];

@Component({
    templateUrl: './select-organization-dialog.component.html',
})
export class SelectOrganizationDialog implements OnInit {
    destroyRef = inject(DestroyRef);

    selectedOrganizationId?: number;
    filterControl = new FormControl('');
    organizationRoleControl = new FormControl<CustomerRole>(null);
    organizationRoles = [];
    organizationList: Organization[] = [];
    pinnedOrganizations: number[] = [];

    get filterTerm() {
        return this.filterControl.value;
    }

    get organizationId() {
        return getCustomerId();
    }

    get organizationRole() {
        return getCustomerRole();
    }

    get filteredOrganizationList() {
        return this.organizationList
            .filter(
                (organization) =>
                    organization.role == this.organizationRoleControl.value,
            )
            .sort((a, b) => {
                if (a.id === this.selectedOrganizationId) {
                    return -1;
                }
                if (b.id === this.selectedOrganizationId) {
                    return 1;
                }
                return 0;
            });
    }

    get selectedOrganization(): Organization {
        return this.organizationList.find(
            (c) => c.id == this.selectedOrganizationId,
        );
    }

    get onlyRoles(): CustomerRole[] {
        return this.data.onlyRoles ?? customerRoleArray;
    }

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<SelectOrganizationDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title?: string;
            organizationId?: number;
            onlyRoles?: CustomerRole[];
            organizations?: Organization[];
        },
    ) {
        this.data ??= {};
        this.data.title ??= 'organization';
        this.selectedOrganizationId =
            this.data.organizationId ?? this.organizationId;
        for (
            let role = this.organizationRole;
            role <= CustomerRole.Client;
            role += 1
        ) {
            if (this.onlyRoles.includes(role)) {
                this.organizationRoles.push({
                    name: organizationRoleTerms[role],
                    value: role,
                });
            }
        }
    }

    async ngOnInit() {
        await ensureLoaded([this.api.organizations]);
        this.organizationList =
            this.data?.organizations ??
            this.api.organizations.current({ sort: 'name' });
        if (
            this.selectedOrganization &&
            this.onlyRoles.includes(this.selectedOrganization.role)
        ) {
            this.organizationRoleControl.setValue(
                this.selectedOrganization.role,
            );
        } else {
            this.selectedOrganizationId = null;
            this.organizationRoleControl.setValue(
                this.organizationRoles[0].value,
            );
        }

        this.api.pins
            .listen({ type: 'organization' })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pins) => {
                this.pinnedOrganizations = pins
                    .sort((a, b) => a.order - b.order)
                    .map((p) => p.itemId);
                this.updateOrganizationList();
            });
    }

    updateOrganizationList() {
        const sortedOrganizations = this.organizationList.sort((a, b) => {
            const aIsPinned = this.pinnedOrganizations?.includes(a.id);
            const bIsPinned = this.pinnedOrganizations?.includes(b.id);

            if (aIsPinned && bIsPinned) {
                // Sort pinned organizations by their order.
                return (
                    this.pinnedOrganizations.indexOf(a.id) -
                    this.pinnedOrganizations.indexOf(b.id)
                );
            } else if (aIsPinned !== bIsPinned) {
                // Sort pinned organizations first.
                return aIsPinned ? -1 : 1;
            } else {
                return a.name.localeCompare(b.name);
            }
        });
        this.organizationList = sortedOrganizations;
    }

    getImageUrl(organization) {
        return getOrganizationImageUrl(organization.id, 'logo');
    }
}
